import React, { useState } from 'react';
import './App.css';
import LaserPage from './lasersPage';
import DeepFake from './deepfakePage';
import Twitch from './twitch';
import AboutMe from './aboutMePage';
import TrackingPage from './trackingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import DirectoryPage from './DirectoryPage2';
import ApexRotationAdvice from './rotationAdvicePage';
import ApexAIAimbot from './objectDetectionPage';
import { useNavigate } from 'react-router-dom';
import BreakingTurtleGraphics from './breakingTurtleGraphicsPage';
import { Container } from 'reactstrap';
import { PhysicsPage } from './physicsPage';
//eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Input, FormGroup, Label, Form } from 'reactstrap';


function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [simpleMode, setSimpleMode] = useState(false);
    const [homePage, setHomePage] = useState("/");


    const setUpSimpleMode = (mode) => {
        if (mode) {
            setSimpleMode(true);
            if (location.pathname === '/') {
                navigate('/old');
            }
            setHomePage("/old");
        }
        else {
            setSimpleMode(false);
            if (location.pathname === '/old') {
                navigate('/');
            }
            setHomePage("/");
        }
        //only navigate to /old if we would be in the phhysics page

    }

    return (
        <div className={`app ${simpleMode ? 'simple-background' : 'pretty-background'}`}>
            {/* {loading && <LoadingScreen />} */}

            <button className="HomeButton" onClick={() => navigate(homePage)} style={{ position: 'fixed', top: '0', left: '0', zIndex: '10000' }}>Home</button>

            <div className={simpleMode ? "simple" : 'grid-container'}>
                <div className={simpleMode ? "simple" : 'grid'} />
            </div>

            <Container className={simpleMode ? "simple-header" : 'neon'} style={{ textAlign: 'center', margin: 'auto', padding: '10px', position: 'fixed', top: '0', left: '0', right: '0' }}
                onClick={() => window.location.href = '/'}
            >
                <h1>Bevan's Portfolio</h1>
            </Container>
            <div className="scrollable-content">
                <Routes>
                    <Route path="/" element={<PhysicsPage />} />
                    <Route path="/twitch" element={<Twitch />} />
                    <Route path="/tracking" element={<TrackingPage />} />
                    <Route path="/projects" element={<DirectoryPage />} />
                    <Route path="/lasers" element={<LaserPage />} />
                    <Route path="/deepfake" element={<DeepFake />} />
                    <Route path="/aboutMe" element={<AboutMe />} />
                    <Route path="/rotationAdvice" element={<ApexRotationAdvice />} />
                    <Route path="/objectDetection" element={<ApexAIAimbot />} />
                    <Route path="/breakingTurtleGraphics" element={<BreakingTurtleGraphics />} />
                    <Route path="/old" element={<DirectoryPage />} />

                </Routes>
                <div className='vertical-text' style={{ pointerEvents: 'none' }}>
                    <p>©2024 Bevan</p>
                </div>
                <Form>
                    <FormGroup switch>
                        <Input type="switch" role="switch" onChange={() => setUpSimpleMode(!simpleMode)} checked={simpleMode} onFocus={e => e.target.blur()} style={{ pointerEvents: 'auto', position: 'fixed', bottom: '4px', right: '20px', zIndex: '10000' }} className='simpleModeSwitch' />
                        <Label check style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '10', marginRight: '60px', color: 'white' }}>Simple Mode</Label>
                    </FormGroup>
                </Form>
            </div>
        </div >
    );
}

export default App;
