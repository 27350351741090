
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';
const TrackingPage = () => {
    // Define the project details and images as data, assuming they are stored in your public/images folder
    const projectDetails = {
        title: 'Elevating Game Analysis: The Apex Legends Analysis Tool',
        sections: [
            {
                header: 'Introduction:',
                content: "In the competitive and intricate world of Apex Legends, understanding the nuances of gameplay is crucial for players looking to enhance their skills and strategies. The Apex Legends Analysis Tool is the first of it's kind, a project that brings a comprehensive suite of modules designed to analyze various aspects of gameplay, providing players and enthusiasts with deep insights into player performance, game mechanics, and much more. Developed through a collaborative effort with professionals in the field, this tool stands as the gold standard for Apex Legends analysis.",
            },
            {
                header: 'A Revolutionary Web Interface:',
                content: (
                    <span>
                        The recent addition of a web interface, based on Flask, marks a significant milestone for the Apex Legends tracker. This interface transforms the user experience, making the tool’s advanced analytics accessible to a wider audience. Users can now interact with various modules through a user-friendly web interface, streamlining the analysis process.
                        <br />
                        <br />
                        <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                            <li>
                                <strong>Real-Time Data and Image Transmission:</strong>Real-Time Data and Image Transmission: The web interface introduces real-time socket communication through Socket.IO, facilitating the transmission of images and data as they are processed. This feature ensures that users have a visual representation of what's being tracked, providing immediate feedback and enhancing the understanding of the game dynamics.
                            </li>
                            <li>
                                <strong>Multi-Threading for Efficient Processing:</strong>Multi-Threading for Efficient Processing: With support for multi-threading, the tool ensures that data is processed swiftly and efficiently, leveraging the power of multiple processors. This feature is crucial for handling the complex and data-intensive tasks involved in gaming analysis
                            </li>
                            <li>
                                {' '}
                                <strong>Simplified User Experience:</strong>The web interface significantly simplifies the user experience, allowing users to interact with the tool without the need for individual command lines. This ensures that even users with limited technical expertise can leverage the tool’s advanced analytics.
                            </li>
                        </ul>
                    </span>
                ),
            },
            {
                header: 'Delving Deeper: Modules Overview',
                content: (
                    <span>
                        The Apex Legends Analysis Tool is equipped with a wide range of modules, each designed to provide in-depth insights into various aspects of gameplay. The tool’s modules include, but are not limited to:
                        <br />
                        <br />
                        <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                            <li>
                                <strong>Video Decomposition Tool:</strong>Video Decomposition Tool: This module is designed to break down gameplay videos into individual frames, allowing for a detailed analysis of player movements, enemy encounters, and other crucial aspects of gameplay. The module slices the frames into sub-sections that are each individually analyzed, providing a comprehensive overview of the game dynamics.
                            </li>
                            <li>
                                <strong>Coordinator:</strong> The coordinator script serves to manage the various modules, ensuring that they work in tandem to provide a comprehensive analysis. The coordinator is responsible for orchestrating the flow of data and images between the modules, ensuring that the analysis is conducted seamlessly.
                            </li>
                            <li>
                                <strong>Damage Tracker:</strong> The damage tracker module is designed to analyze the damage inflicted by players, providing insights into the effectiveness of their strategies and weapons. The module tracks the damage inflicted on enemies, as well as the damage sustained by the player, providing a comprehensive overview of the player’s performance.
                            </li>
                        </ul>
                    </span>
                ),
            },
            {
                header: 'Conclusion:',
                content: (
                    <span>
                        The 'Apex Legends Analysis Tool' represents a paradigm shift in the realm of gaming analytics, offering a multi-faceted view of Apex Legends gameplay. With over 4000 lines of code, it encapsulates advanced image processing techniques, OCR, and meticulous data management, ensuring that every aspect of the game is analyzed with precision. The addition of the web interface further democratizes access to these insights, ensuring that whether you are a casual gamer, a competitive player, or a data enthusiast, this tool stands ready to elevate your understanding of Apex Legends. Dive in, explore its features, and transform your approach to game analysis. Check out the <a href="https://github.com/happihound/Apex-Analysis-Toolset">GitHub</a> for a more indepth look in the
                        code!
                    </span>
                ),
            },
        ],
        images: [
            { src: '/images/apex_tracking/Damage_Tracker_Graph.jpg', caption: 'Example graph of player damage' },
            { src: '/images/apex_tracking/Evo_Tracker_Graph.jpg', caption: 'Example graph of player Evo' },
            { src: '/images/apex_tracking/Player_Health_Tracker_Graph.jpg', caption: 'Example graph of player health' },
            { src: '/images/apex_tracking/Player_Ult_Tracker_Graph.jpg', caption: "Example graph of player's time till their ult is ready" },
            { src: '/images/apex_tracking/Player_Gun_Tracker_Graph.jpg', caption: 'Example graph of player gun usage' },
            { src: '/images/apex_tracking/unknown-D237D.jpg', caption: 'An image depicting the keypoints detected on one of the maps. Used for matching minimap to location' },
            { src: '/images/apex_tracking/unknown-18B4C.jpg', caption: '2D heatmap of time spent in locations.' },
            { src: '/images/apex_tracking/unknown-53D58.jpg', caption: '3D histogram of time spent in locations, smoothing applied.' },

            // Add more image paths and captions as needed
        ],
    };

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <div>
                <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                    <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', textWrap: 'balance', padding: '20px' }}>
                        <h1>{projectDetails.title}</h1>
                        {projectDetails.sections.map((section, index) => (
                            <div key={index}>
                                <h3>{section.header}</h3>
                                <p>{section.content}</p>
                            </div>
                        ))}
                        <h3>There is much to see, so the gallery section on this page is quite long. Enjoy!</h3>
                    </div>
                </Container>
            </div>
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <CardImg top src={`${process.env.PUBLIC_URL}/images/apex_tracking/home.jpg`} alt="Banner" style={{ cursor: 'pointer', borderRadius: '15px', objectFit: 'cover' }} />
                            <CardText className="text-center">The home page of the tracking tool. Here you can access all the other tools.</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <CardImg top src={`${process.env.PUBLIC_URL}/images/apex_tracking/minimap_webpage.jpg`} alt="Banner" style={{ cursor: 'pointer', borderRadius: '15px', objectFit: 'cover' }} />
                            <CardText className="text-center">The web interface of the Apex Legends Analysis Tool</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <CardImg top src={`${process.env.PUBLIC_URL}/images/apex_tracking/Decomp_Key.jpg`} alt="Banner" style={{ cursor: 'pointer', borderRadius: '15px', objectFit: 'cover' }} />
                            <CardText className="text-center">The key for extracting data. Everything you see labedls and boxed can be tracked using the tool.</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ borderRadius: '30px', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default TrackingPage;
