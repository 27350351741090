import React, { useState, useEffect, useRef, useMemo } from "react";
import Matter from 'matter-js';
import 'pathseg';
// import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
// import LoadingScreen from "./loadingScreen";
import OverlayText from "./overlay";

export function PhysicsPage() {
    const sceneRef = useRef(null);
    const staticRef = useRef(null);
    const [itemSelected, setItemSelected] = useState(null);
    const navigate = useNavigate(); // Initialize the navigate function
    const [lastDragTime, setLastDragTime] = useState(Infinity);
    const engine = useMemo(() => (Matter.Engine.create()), []);
    // const [isLoading, setIsLoading] = useState(false);
    const [overlayText, setOverlayText] = useState("Drag an icon to the TV and drop it to learn more");
    const [overlayLocation, setOverlayLocation] = useState({ x: 0, y: 0 });

    //lazy load in the gifs used in the physics page
    //if the size of the window changes, reload the page to ensure the physics engine is still working
    useEffect(() => {
        window.addEventListener('resize', () => {
            window.location.reload();
        });
    }
        , []);

    useEffect(() => {
        const { Engine, Render, Runner, Bodies, World, Common, Body, Events } = Matter;
        const world = engine.world;
        Common.setDecomp(require('poly-decomp'));

        const render = Render.create({
            element: sceneRef.current,
            engine: engine,
            options: {
                width: window.innerWidth,
                height: window.innerHeight,
                wireframes: false,
                background: 'transparent',
                showSleeping: false,
                showConvexHulls: true

            }
        });



        const ground = Bodies.rectangle(window.innerWidth / 2, window.innerHeight, window.innerWidth, window.innerHeight * 0.01, {
            isStatic: true,
            render: {
                fillStyle: 'transparent'
            }
        });
        const ceiling = Bodies.rectangle(window.innerWidth / 2, 0, window.innerWidth, window.innerHeight * 0.01, {
            isStatic: true,
            render: {
                fillStyle: 'transparent'
            }
        });

        const leftWall = Bodies.rectangle(0, window.innerHeight / 2, window.innerWidth * 0.01, window.innerHeight, {
            isStatic: true,
            render: {
                fillStyle: 'transparent'
            }
        });

        const rightWall = Bodies.rectangle(window.innerWidth, window.innerHeight / 2, window.innerWidth * 0.01, window.innerHeight, {
            isStatic: true,
            render: {
                fillStyle: 'transparent'
            }
        });

        const mouse = Matter.Mouse.create(render.canvas);

        const mouseConstraint = Matter.MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: { visible: false }
            }
        });

        World.add(world, mouseConstraint);
        render.mouse = mouse;
        World.add(world, [ground, ceiling, leftWall, rightWall]);


        const makeIcons = async (scale, iconItem) => {
            var text = iconItem.text;

            var x = iconItem.spawnPoint.x;
            var y = iconItem.spawnPoint.y;
            var offsetY = -69 * scale / 1.25;
            y += offsetY;
            var svgScale = iconItem.scale;
            var icon = null;
            if (iconItem.text === "Aimbot" || iconItem.text === "Rotation") {
                icon = Bodies.circle(x, y, 25 * scale, {
                    render: {
                        strokeStyle: '#ffffff',
                        fillStyle: 'red',
                        sprite: {
                            texture: `${process.env.PUBLIC_URL}/images/physicsSVGs/${text}2.png`,
                            xScale: svgScale * scale / 2,
                            yScale: svgScale * scale / 2
                        },
                    },
                    friction: 1,        // Increase this value to make the object slide less
                    frictionStatic: 1.0,  // Increase this value to make the object start sliding less easily
                    density: 1000
                });
            } else {
                icon = Bodies.rectangle(x, y, 50 * scale, 50 * scale, {
                    render: {
                        strokeStyle: '#ffffff',
                        fillStyle: 'red',
                        sprite: {
                            texture: `${process.env.PUBLIC_URL}/images/physicsSVGs/${text}2.png`,
                            xScale: svgScale * scale / 2,
                            yScale: svgScale * scale / 2
                        },
                    },
                    friction: 1,        // Increase this value to make the object slide less
                    frictionStatic: 1.0,  // Increase this value to make the object start sliding less easily
                    density: 1000
                });
            }




            icon.label = {
                name: text,
                spawnPoint: { x: x, y: y },
                disableSpawning: false
            }

            World.add(world, [icon]);

            return icon;
        }


        const createShelf = async (x, y, width, height, image, scale) => {
            const shelf = Bodies.rectangle(x, y, width * scale, height * scale, {
                isStatic: true,
                render: {
                    strokeStyle: 'red',
                    lineWidth: 3,

                },
                friction: 1,
                frictionStatic: 1,
                frictionAir: 0.05,
                density: 1000
            });

            var textElement = Bodies.rectangle(x, y + 3 * scale, 5 * scale, 5 * scale, {
                isStatic: true,

                render: {
                    strokeStyle: '#ffffff',
                    sprite: {
                        texture: `${process.env.PUBLIC_URL}/images/physicsSVGs/${image}.png`,
                        xScale: 0.35 * scale / 2,
                        yScale: 0.35 * scale / 2,

                    },
                    lineWidth: 1,

                },

            });

            World.add(world, [shelf, textElement]);
        };
        //calculate scale based on mobile or desktop, if mobile use a smaller scale, if desktop use a larger scale
        var scale = window.innerWidth < 600 || window.innerHeight < 600 ? 0.75 : 2;
        var tv = Bodies.rectangle(window.innerWidth / 2, window.innerHeight / 2, 200 * scale, 150 * scale, {
            isStatic: true,
            isSensor: true,
            render: {
                strokeStyle: '#ffffff',
                fillStyle: 'red',
                sprite: {
                    texture: `${process.env.PUBLIC_URL}/images/physicsSVGs/tv2.png`,
                    xScale: scale,
                    yScale: scale
                },
            },
            friction: 1,        // Increase this value to make the object slide less
            frictionStatic: 1.0,  // Increase this value to make the object start sliding less easily
            frictionAir: 0.05,    // Optional: Increase this to add air resistance
            density: 1000
        });

        tv.label = {
            name: "tv",
        }
        World.add(world, [tv]);


        var tvScreen = Bodies.rectangle(window.innerWidth / 2, window.innerHeight / 2, 180 * scale, 125 * scale, {
            isStatic: true,
            isSensor: true,
            render: {
                strokeStyle: '#ffffff',
                fillStyle: 'transparent',
            },
            friction: 1,        // Increase this value to make the object slide less
            frictionStatic: 1.0,  // Increase this value to make the object start sliding less easily
            frictionAir: 0.05,    // Optional: Increase this to add air resistance
            density: 1000
        });

        staticRef.current = tvScreen;

        tv.label = {
            name: "tvscreen",
        }
        World.add(world, [tvScreen]);

        let collidingItems = new Set();

        Events.on(engine, 'collisionStart', function (event) {
            event.pairs.forEach(pair => {
                if (pair.bodyA === tv || pair.bodyB === tv) {
                    const collidingItem = pair.bodyA === tv ? pair.bodyB : pair.bodyA;
                    collidingItems.add(collidingItem);
                    updateStaticDisplay();
                }
            });
        });

        Events.on(engine, 'collisionEnd', function (event) {
            event.pairs.forEach(pair => {
                if (pair.bodyA === tv || pair.bodyB === tv) {
                    const collidingItem = pair.bodyA === tv ? pair.bodyB : pair.bodyA;
                    collidingItems.delete(collidingItem);
                    updateStaticDisplay();
                }
            });
        });


        //lets ensure that the objects return to their spawn point if they get off screen somehow
        Events.on(engine, 'beforeUpdate', function (event) {
            const objects = Matter.Composite.allBodies(world);
            objects.forEach(object => {
                //also ensure the object isn't in the user's hand
                if (object.label && object.label.disableSpawning) {
                    return;
                }
                if (object.position.x < 0 || object.position.x > window.innerWidth || object.position.y < 0 || object.position.y > window.innerHeight) {
                    Body.setPosition(object, object.label.spawnPoint);
                    Body.setVelocity(object, { x: 0, y: 0 });
                    Body.setAngle(object, 0);
                    Body.setAngularVelocity(object, 0);
                }
            });
        });

        Events.on(mouseConstraint, 'mousedown', function (event) {
            setLastDragTime(Infinity);
            setOverlayText(null);
        });

        Events.on(mouseConstraint, 'mouseup', function (event) {
            setLastDragTime(Date.now());
        });

        const updateStaticDisplay = () => {
            if (collidingItems.size === 1) {
                turnOnStatic();
            } else {
                turnOffStatic();
            }
        };
        var tvScreenElement = document.getElementById("tvscreen");
        tvScreenElement.style.backgroundSize = "cover";
        tvScreenElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/physicsSVGs/noSignal.png)`;
        tvScreenElement.style.width = 200 * scale + "px";
        tvScreenElement.style.height = 130 * scale + "px";
        tvScreenElement.style.position = "absolute";
        tvScreenElement.style.top = window.innerHeight / 2 - 75 * scale + "px";
        tvScreenElement.style.left = window.innerWidth / 2 - 110 * scale + "px";
        tvScreenElement.style.zIndex = -1;


        const turnOffStatic = () => {
            var tvScreen = document.getElementById("tvscreen");
            if (tvScreen === null) {
                return;
            }
            tvScreenElement.style.left = window.innerWidth / 2 - 110 * scale + "px";
            tvScreen.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/physicsSVGs/noSignal.png)`;
            setItemSelected(null);
        }

        const turnOnStatic = () => {
            var tvScreen = document.getElementById("tvscreen");
            if (tvScreen === null) {
                return;
            }
            tvScreen.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/physicsSVGs/static.gif)`;

            setTimeout(() => {
                var item = Array.from(collidingItems)[0];
                if (item === undefined) {
                    return;
                }
                setItemSelected(item.label.name);
                tvScreen.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/physicsSVGs/${item.label.name}.gif)`;
                tvScreenElement.style.left = window.innerWidth / 2 - 100 * scale + "px";

            }, 150);
        }

        var iconNames =
            [
                {
                    text: "Lasers",
                    path: "/lasers",
                    scale: 0.40,

                    spawnPoint: { x: 7 * window.innerWidth / 8, y: window.innerHeight / 5 }

                }, {
                    text: "twitchto",
                    path: "/twitch",
                    scale: 0.45,
                    spawnPoint: {
                        x: window.innerWidth / 8, y: window.innerHeight / 5
                    },
                },
                {
                    text: "Deepfakes",
                    path: "/deepfake",
                    scale: 0.40,

                    spawnPoint: { x: 7 * window.innerWidth / 8, y: window.innerHeight * 2 / 5 }
                }, {
                    text: "Rotation",
                    path: "/rotationAdvice",
                    scale: 0.40,

                    spawnPoint: { x: window.innerWidth / 8, y: window.innerHeight * 2 / 5 }
                },
                {
                    text: "ApexTracking",
                    path: "/tracking",
                    scale: 0.40,

                    spawnPoint: { x: 7 * window.innerWidth / 8, y: 3 * window.innerHeight / 5 }
                },

                {
                    text: "Aimbot",
                    path: "/objectDetection",
                    scale: 0.40,

                    spawnPoint: { x: window.innerWidth / 8, y: 3 * window.innerHeight / 5 }

                }, {
                    text: "Aboutme",
                    path: "/aboutMe",
                    scale: 0.40,

                    spawnPoint: { x: 7 * window.innerWidth / 8, y: window.innerHeight * 4 / 5 }
                },
                {
                    text: "Turtle",
                    path: "/breakingTurtleGraphics",
                    scale: 0.5,

                    spawnPoint: { x: window.innerWidth / 8, y: window.innerHeight * 4 / 5 }
                },

            ];
        setOverlayLocation({
            x: window.innerWidth / 2, y: 1 * window.innerHeight / 7
        });
        iconNames.forEach(async (icon) => {
            await makeIcons(scale, icon);
        });

        iconNames.forEach(async (iconItem, index) => {
            var row = Math.floor(index / 2);
            createShelf(
                index % 2 ? window.innerWidth / 8 : 7 * window.innerWidth / 8,
                window.innerHeight / 5 + row * window.innerHeight / 5,
                150,
                20,
                iconItem.text,
                scale)
        });




        engine.gravity.y = 0;
        Render.run(render);
        const runner = Runner.create();
        Runner.run(runner, engine);

        setTimeout(() => {
            engine.gravity.y = 1;

            engine.gravity.x = 0;
            engine.enableSleeping = true;
        }, 1000);





        return () => {
            Render.stop(render);
            Runner.stop(runner);
            World.clear(world, false);
            Engine.clear(engine);
            //eslint-disable-next-line
            if (sceneRef.current) {
                //eslint-disable-next-line
                sceneRef.current.removeChild(render.canvas);
            }
        }

    }, [engine]);

    useEffect(() => {
        if (itemSelected && (lastDragTime !== Infinity && Date.now() - lastDragTime < 1000)) {
            const pathMap = {
                twitchto: "/twitch",
                Lasers: "/lasers",
                Deepfakes: "/deepfake",
                Rotation: "/rotationAdvice",
                ApexTracking: "/tracking",
                Aimbot: "/objectDetection",
                Aboutme: "/aboutMe",
                Turtle: "/breakingTurtleGraphics",
            };
            const pageToGoto = pathMap[itemSelected];
            //get all the bodies in the world
            var objects = Matter.Composite.allBodies(engine.world);
            const { Body, World, Sleeping } = Matter;
            //set all objects to be non static and have them fall away, then navigate to the page
            objects.forEach(object => {
                if (object.isStatic) {
                    //delete the object
                    World.remove(engine.world, object);
                }
                Body.setVelocity(object, { x: 0, y: 10 });
                Body.setAngularVelocity(object, 0);
                Sleeping.set(object, false);
                if (object.label && object.label.disableSpawning !== undefined) {
                    object.label.disableSpawning = true;
                }
            }
            );

            var tvScreen = document.getElementById("tvscreen");
            tvScreen.style.visibility = "hidden";
            // setIsLoading(true);
            // setTimeout(() => {
            navigate(pageToGoto);
            //     setIsLoading(false);
            // }, 750);
            // //cover the screen in a black overlay, hiding everything save the tv
            // tvScreen.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/physicsSVGs/noSignal.png)`;
            // document.getElementById("root").style.backgroundColor = "black";

            // if (pageToGoto) {
            //     navigate(pageToGoto); // Use the navigate function to change the route
            // }
        }
    }, [itemSelected, navigate, lastDragTime, engine]);





    return (
        <div ref={sceneRef} style={{
            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'
        }}>
            {/* <LoadingScreen loading={isLoading} /> */}
            {overlayText && <OverlayText text={overlayText} top={overlayLocation.y} left={overlayLocation.x} />}
            <div id="tvscreen" style={{
            }}></div>
        </div>
    );
}
