
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';

const BreakingTurtleGraphics = () => {
    const projectDetails = {
        title: 'Breaking Turtle Graphics: A Creative Blend of Computer Vision & Art',
        sections: [
            {
                header: 'Introduction:',
                content: 'Art and technology seamlessly come together in the "Breaking Turtle Graphics" project. By employing the power of computer vision and the simplicity of turtle graphics, this project transforms regular images into a captivating array of polygons, presenting a unique rendition of familiar visuals.',
            },
            {
                header: 'Project Vision:',
                content: 'The core aspiration behind this endeavor was to conceive a digital art platform, where images are interpreted and redrawn with a polygonal touch. This project seamlessly blends the precision of computer algorithms with the abstract nature of art, resulting in an artistic visualization of images.',
            },
            {
                header: 'How it Works',
                content: (
                    <span>
                        Image Preprocessing & Extraction: The image undergoes adaptive thresholding to segregate the areas of interest. The script dynamically scales the image if it's detected to be too small, ensuring optimal resolution for processing. Contours are identified within the image to understand distinct boundaries. An approximate polygon representation of the identified contours is generated, providing the foundational lines for the final artwork. <br />
                        Color Analysis: For every contour traced, the system assigns a color. This color isn't random; it's carefully extracted from the mean color of the segment in the original image, preserving the image's authentic hues. <br />
                        Polygon Storage: All identified polygons, along with their respective color codes, are stored efficiently for the drawing phase. <br />
                        Drawing with Turtle Graphics: The humble turtle takes center stage, transforming into the artist of the hour. Each polygon is drawn meticulously, adhering to the coordinates and color derived from the original image. The polygons are scaled appropriately, ensuring the final artwork is proportionate and detailed. The turtle moves intelligently, lifting its pen when it detects larger distances between consecutive drawing points, optimizing the drawing process. <br />
                    </span>
                ),
            },
            {
                header: 'Technical Spotlight',
                content: 'Contour Analysis with OpenCV: The project leverages OpenCV, a powerful computer vision library, to detect contours, approximate polygons, and analyze the colors within the image.\n\n Drawing with Turtle: Turtle graphics provides an interactive canvas to redraw the image, polygon by polygon. The use of turtle not only simplifies the drawing process but also offers a nostalgic and engaging visual experience as the image comes to life.',
            },
        ],
        images: [
            { src: `${process.env.PUBLIC_URL}/images/breakingturtle/image.jpg`, caption: 'The original image, Starry Night by Vincent van Gogh.' },
            { src: `${process.env.PUBLIC_URL}/images/breakingturtle/threshheld.jpg`, caption: 'The result of running a thresholding operation on the image.' },
            { src: `${process.env.PUBLIC_URL}/images/breakingturtle/computed.jpg`, caption: 'The computed contours, drawn with their color components.' },
            { src: `${process.env.PUBLIC_URL}/images/breakingturtle/drawn.jpg`, caption: 'The final image, as drawn by turtle graphics.' },
        ],
    };


    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', padding: '20px' }}>
                    <h1>{projectDetails.title}</h1>
                    {projectDetails.sections.map((section, index) => (
                        <div key={index}>
                            <h3>{section.header}</h3>
                            <p>{section.content}</p>
                        </div>
                    ))}
                </div>
            </Container>
            <br />
            <br />

            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/breakingturtle/drawing turtle.mp4`} type="video/mp4" />
                            <CardText className="text-center">The home page of the tracking tool. Here you can access all the other tools.</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ borderRadius: '30px', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default BreakingTurtleGraphics;
