/**
 * @file ThemeProvider.js
 * @summary Provides the theme context to the application.
 * @description This module provides the theme context to the application, allowing the theme to be toggled between light and dark.
 * @author Bevan
 */
import React, { Component } from 'react';
import ThemeContext from './ThemeContext';
/**
 * ThemeProvider Component - Provides the theme context to the application.
 * @component
 * @param {Object} props Component props
 * @returns {JSX.Element} The theme context to render
 */
class ThemeProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: 'light',
        };
    }

    componentDidMount() {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.setState({ theme: prefersDark ? 'dark' : 'light' });
    }

    toggleTheme = () => {
        this.setState(prevState => ({
            theme: prevState.theme === 'light' ? 'dark' : 'light',
        }));
    };

    render() {
        return (
            <ThemeContext.Provider value={{ theme: this.state.theme, toggleTheme: this.toggleTheme }}>
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}

export default ThemeProvider;
