import React from 'react';

const OverlayText = ({ text, top, left }) => {
    if (!text) return null;
    return (
        <div className="overlay-text" style={{
            top: `${top}px`, left: `${left}px`
        }}>
            {text}
        </div>
    );
};

export default OverlayText;
