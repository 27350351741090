import React from 'react';
import { Container, Row, Col, Card, CardImg, CardText } from 'reactstrap';

const ApexRotationAdvice = () => {
    const projectDetails = {
        title: 'Navigating Apex Legends with Computer Vision',
        sections: [
            {
                header: 'Introduction:',
                content: "Apex Legends isn't just a game for many; it's a universe waiting to be explored. As a top player, I've always been enamored with its strategic depths. Merging my passion for this game with a keen interest in computer vision led to the birth of \"Apex Rotation Advice.\" This project, inspired by a friend's suggestion, is a remarkable deviation from my previous computer vision experiments within Apex.",
            },
            {
                header: 'About the Tool:',
                content: 'Apex Rotation Advice is a Python-based tool designed to suggest the safest path between two locations in the Apex Legends universe. By employing a specialized version of graph pathfinding, the focus is on swift computation of routes based on the actual in-game distance rather than mere node counts.',
            },
            {
                header: 'Why This Project:',
                content: 'Apex holds a special place in my gaming journey. The vision behind this project was to craft a tool that integrates my love for computer vision in a way that enhances gameplay strategy, especially for top-tier teams. The goal was clear: aid squads in formulating game-winning rotation strategies, ensuring fewer hostile encounters.',
            },
            {
                header: 'Technical Features & Highlight:',
                content: 'Universality: Engineered to work seamlessly across different computers, ensuring adaptability.\nEnemy Detection: Advanced algorithms to pinpoint and strategize routes that bypass enemy engagements.\nUser Experience: Transition from traditional command-line interfaces to more interactive user interfaces, making the tool more accessible.\nConsistent Image Processing: Ability to process diverse aspect ratios to a standard 1:1 for accuracy.',
            },
            {
                header: 'Behind the Code:',
                content: "Adhering to the philosophy of clear coding, Python's return/argument hints are generously used throughout for enhanced readability. In tandem, an exhaustive list of tests ensures that every change or addition preserves the project's integrity and functionality.",
            },
            {
                header: 'Future Endeavor:',
                content: 'While the current version offers a plethora of features, the road ahead promises even more. On the horizon is the capability to predict in-game ring movements, further amplifying the strategic gameplay aspect.',
            },
            {
                header: 'Acknowledgments & Collaboration:',
                content: 'Teamwork brings visions to life. A heartfelt shoutout to wattson_x for map labeling, the invaluable data from the Apex Legends Community, and KeanuJ for being the muse behind this venture.',
            },
        ],
        images: [
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/mapWENodes-326B6.jpg`, caption: 'The map with all of the POI and rotations with their names visible.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-EFDB8.jpg`, caption: 'A Close up view of what an association between a POI and its rotation looks like graphically.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-22B042.jpg`, caption: 'The full graph with all POI and chokes marked, mostly for debugging incorrect paths.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-1C8CE.jpg`, caption: 'The selection screen where the user inputs their desired start and end locations.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-954C0.jpg`, caption: 'An example of what a real world input map might look like. Note the red chevrons, as those are areas we want to avoid.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-3C083.jpg`, caption: 'Using thresholding with a HSV image to extract only the enemy locations.' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-02765.jpg`, caption: 'An example of the CLI output. Start, end, distance, and path are all noted. Areas to avoid are also present on the output (Not pictured)' },
            { src: `${process.env.PUBLIC_URL}/images/apexrotation/image-B4D34.jpg`, caption: 'An example of the output a user would see, having safely navigated around the enemies. Enemies are shown as there red dots.' },
        ],
    };

    return (
        <div>
            <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', padding: '20px' }}>
                    <h1>{projectDetails.title}</h1>
                    {projectDetails.sections.map((section, index) => (
                        <div key={index}>
                            <h3>{section.header}</h3>
                            <p>{section.content}</p>
                        </div>
                    ))}
                </div>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ApexRotationAdvice;
