
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';

const LaserPage = () => {
    // Define the project details and images as data, assuming they are stored in your public/images folder
    const projectDetails = {
        title: 'Optical Fascination: A Journey from Engraving Lasers to High-Power Diodes Arrays',
        sections: [
            {
                header: 'Introduction:',
                content: 'Lasers, with their concentrated beams of light, have always captivated my imagination and curiosity. From a young age, influenced by my mom’s work in optics, my interest in lasers and their potential has always been a fascination of mine. The journey from a basic engraving laser to constructing a high-powered diode laser array was a journey that took nearly a decade.',
            },
            {
                header: 'Where it began:',
                content: 'It all started with a simple engraving laser purchased from Amazon. My goal was to modify it, make it portable, and understand its workings. This initial step into a dubiously safe hobby opened the door for higher power experiments, leading to the creation of my own laser with a custom diode array and driver board. The process of creating this laser was a long and arduous one, but the end result was a laser that could cut through wood and plastic with ease. The laser was also capable of engraving metal and glass, and it was a great learning experience.',
            },
            {
                header: 'Challenges and Solutions:',
                content: (
                    <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                        <li>The most challenging part of this project was the construction of the diode array. The diodes had to be carefully aligned and mounted to ensure that the laser would work as intended. The driver board was also a challenge, as it had to be designed to handle the high current and voltage required by the diodes. The project taught me a lot about electronics, heat management, and laser safety. It was a great learning experience, and I am proud of the end result.</li>
                        <br />
                        <li>The laser was designed to output 85 watts of optical light. Current laser technology results in about a 45 to 50% efficiency, so the system had to be able to both supply and dissipate several hundred watts of heat. The laser was initially cooled with a pelter cooler, but this was later replaced with a 10 pound aluminum heat sink from a minifridge.</li>
                        <br />
                        <li> The power dissipation issue solved, I had to figure out how to power the diodes. The only commercially available batteries that could supply 50 to 60 amps at 12 volts were lithium polymer batteries, traditionally used in RC cars and drones. These batteries are known for their high energy density, but also for their volatility. The batteries were always handled with extreme caution, and the laser was never left unattended while powered.</li>
                        <br />
                    </ul>
                ),
            },
            {
                header: 'Takeaways:',
                content: "Having never worked with high ampere circuits before, this project was a great experience, with relatively minor risks. There were numerious issues I haven't mentioned here but, in the end, I finally completed a project I'd dreamed about since I was 9 years old.",
            },
        ],
        images: [
            { src: '/images/lasers/burn.jpg', caption: 'Laser in action' },
            { src: '/images/lasers/diagram.jpg', caption: 'Circuit diagram for the diode array' },
            { src: '/images/lasers/current_top.jpg', caption: 'Current top view after the rebuild' },
            { src: '/images/lasers/current_side_2.jpg', caption: 'Current side view after the rebuild' },
            { src: '/images/lasers/current_side.jpg', caption: 'Alternate side view after the rebuild' },
            { src: '/images/lasers/current_front.jpg', caption: 'Current front view after the rebuild' },
            { src: '/images/lasers/lasing.jpg', caption: 'First lasing of the diode array' },
            { src: '/images/lasers/temp.jpg', caption: 'Temperature just after laser was removed from the target' },

            // Add more image paths and captions as needed
        ],
    };
    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <div>
                <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                    <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', textWrap: 'balance', padding: '20px' }}>
                        <h1>{projectDetails.title}</h1>
                        {projectDetails.sections.map((section, index) => (
                            <div key={index}>
                                <h3>{section.header}</h3>
                                <p>{section.content}</p>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>

            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ borderRadius: '30px', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default LaserPage;
