import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './subcomponents/ThemeProvider';
import ThemeContext from './subcomponents/ThemeContext';
import { BrowserRouter } from 'react-router-dom';
<link rel="file" href="%PUBLIC_URL%/CNAME" />;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider>
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <div id="app-color-alt" className={`app-color-alt ${theme}`} data-theme={theme} data-bs-theme={theme} theme={theme}>
                            <div className={`app ${theme}`} data-theme={theme} data-bs-theme={theme} theme={theme}>
                                {<App />}
                            </div>
                        </div>
                    )}
                </ThemeContext.Consumer>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
