import React from 'react';
import { Container } from 'reactstrap';

const AboutMe = () => {
    return (
        <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
            <div className="bg-dark text-white text-center py-5" style={{ margin: 'auto', borderRadius: '30px', padding: '20px' }}>
                <div>
                    <h1>About Bev</h1>
                    <p>Hello! I'm Bevan, a software engineer, currently broadening my horizons at the Rochester Institute of Technology in Rochester, NY.</p>
                    <p>My journey with technology began a lifetime ago. As a child, I dabbled with .bat files and even put together a simple website for my third-grade class. Over the years, this curiosity has led me down a path of exploration in machine learning, robotics, and laser photonics.</p>
                    <p>Learning, for me, is about taking risks and embracing challenges. I often find myself diving headfirst into projects, seeking to understand the intricacies of each challenge. One of the projects that I'm particularly fond of is Twitch.To, a novel website allowing for the generation of AI art work. This project was a great learning experience, and it was a joy to see the community's positive response to it.</p>
                    <p>In the esports world, I've been fortunate to have some memorable experiences with Apex Legends. Reaching a high ranking has been a testament to countless hours of practice and learning. This journey has also opened doors to impactful collaborations, such as fundraising with Post Malone, where we successfully raised over $200,000 for various causes.</p>
                    <p>Looking to the future, I'm hopeful about venturing deeper into the machine learning research field. The idea of blending research with real-world applications excites me, and I'm keen to see where this path takes me.</p>
                    <p>One thing I've come to appreciate is the importance of perspective. While I'm passionate about my projects and endeavors, I always strive to stay grounded, valuing the insights and feedback of peers and mentors.</p>
                    <p>When I'm not coding or gaming, I'm often engrossed in my fascination with lasers or the latest developments in robotics. These interests keep me motivated and inspire me to think about the myriad ways technology can be harnessed for good.</p>
                    <p>If you'd like to chat or explore potential collaborations, I'm always open to connecting. Let's learn and grow together!</p>
                    <p>
                        You can contact me at my{' '}
                        <a href="mailto:bevan@bevanneib.com " target="_blank" rel="noreferrer">
                            email
                        </a>{' '}
                        or find me on{' '}
                        <a href="https://www.linkedin.com/in/bevan-neiberg/" target="_blank" rel="noreferrer">
                            LinkedIn
                        </a>
                        .
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default AboutMe;
