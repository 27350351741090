
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';

const ApexAIAimbot = () => {
    const projectDetails = {
        title: 'Apex AI Aimbot: Bridging Computer Vision and Gaming',
        sections: [
            {
                header: 'Introduction:',
                content: 'Embarking on a journey to bring artificial intelligence into the captivating world of Apex Legends, my aspiration was audacious: Design an AI capable of autonomously playing the game. Recognizing the quintessence of aim in Apex, I ventured into crafting a computer vision aimbot, a pivotal component that can elevate AI gameplay to human levels.',
            },
            {
                header: 'Technological Deep Dive:',
                content: [
                    'Object Detection with YOLO V8: Employing YOLO V8, I trained the model on thousands of images of the in-game dummy. Through this, the neural network was attuned to pinpoint the dummy’s position on the screen, setting the stage for advanced aiming logic.',
                    <br />,
                    'Cursor Smoothing: A paramount challenge was achieving fluid mouse movement programmatically. This led to the design of a custom smoothing algorithm, emulating the fluidity and precision of controller inputs.',
                    <br />,
                    "Vector-Based Target Acquisition: By representing the bounding box’s location relative to the screen’s center as a vector, precise aiming directions were achieved. Utilizing numpy's arctan2, a ray was traced from the origin to the target coordinates. This methodology leveraged vectors to determine the movement direction and magnitude.",
                    <br />,
                    'Decay Values and Movement Dynamics: To achieve nuanced cursor dynamics – rapid movements when distant from the target and slowing down upon approach – I integrated decay values. These values determined the step size, ensuring the AI cursor approaches its target effectively.',
                    <br />,
                    'Akima Spline Interpolation: Beyond the vector approach, akima spline interpolation played a role in predicting subsequent positions for the vector, providing an additional layer of movement refinement.',
                    <br />,
                    'Performance and Responsiveness: With the primary objective detection running on the GPU and supplementary tasks on the CPU, the program ran over 20 FPS. Furthermore, to ensure AI adaptability, if no target is detected within 5 seconds, a target search algorithm activates, spinning and taking randomized steps to evade potential threats.',
                    <br />,
                    'Multithreading Prowess: The entire program is finely threaded, operating across six different threads, ensuring seamless functioning across its various components.',
                    <br />,
                ],
            },
            {
                header: 'Gallery',
                content: 'Example videos and images showcasing various stages of development and training results.',
            },
        ],
        images: [
            // Assuming images are stored in your public/images folder and named appropriately
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image-43E22.jpg`, caption: 'An example of good pose estimation, my first attempt at object detection.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/bad.jpg`, caption: 'The result of a poor recognition, this is the reason I stopped using pose estimation.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image-CAEB8.jpg`, caption: 'Another example of poor pose estimation.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image3.jpg`, caption: 'The result of the pose estimation model on a real human being. You can see the difference in quality.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/labels.jpg`, caption: 'The spread of the locations of the bounding box, a result of the training process and used for evaluation.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch0.jpg`, caption: 'Training result showing the predicted location on given test images. This was the first round of training.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch1.jpg`, caption: 'The second round of training.' },
            { src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch2.jpg`, caption: 'The final round of training. This training took around 16 hours total on a 4090.' },
        ],
    };
    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', padding: '20px' }}>
                    <h1>{projectDetails.title}</h1>
                    {projectDetails.sections.map((section, index) => (
                        <div key={index}>
                            <h3>{section.header}</h3>
                            <p>{section.content}</p>
                        </div>
                    ))}
                </div>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/apex_aimbot/ai_view.mp4`} type="video/mp4" />
                            <CardText className="text-center">The AI in action</CardText>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/apex_aimbot/game_view.mp4`} type="video/mp4" />
                            <CardText className="text-center">The game in action</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ApexAIAimbot;
