/**
 * @file ThemeContext.js
 * @summary Context for the theme of the application.
 * @description This module contains the context for the theme of the application, provides dark and light themes.
 * @author Bevan
 */
import React from 'react';
/**
 * ThemeContext - Context for the theme of the application.
 * @const
 * @type {Object}
 * @property {string} theme The theme of the application.
 * @property {Function} toggleTheme The function to toggle the theme of the application.
 * @returns {Object} The theme context to render
 */
const ThemeContext = React.createContext({
    theme: 'light', // Default value
    toggleTheme: () => { },
});

export default ThemeContext;
