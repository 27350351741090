
import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';

const DeepFake = () => {
    // Define the project details and images as data, assuming they are stored in your public/images folder
    const projectDetails = {
        title: 'DeepFaking Mastery: An Odyssey into Machine Learning & Data Management',
        sections: [
            {
                header: 'Introduction:',
                content: 'The allure of machine learning, paired with a playful idea of face-swapping a friend onto a celebrity, was the inception of the DeepFaking project. Starting as just an interesting topic, it quickly lead down the rabit whole into a comprehensive exploration of handling vast datasets, crafting machine learning models, and the intricate art of deepfaking.',
            },
            {
                header: 'Genesis of the Project:',
                content: 'At its core, this project was about transplanting a friend\'s face onto the likes of AOC and even Dwayne "The Rock" Johnson. But as with any experiment, the journey held much more than the destination: a deep dive into data management, model learning, and the sheer compute power required for deepfaking.',
            },
            {
                header: 'Challenges & Learnings:',
                content: (
                    <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                        <li>
                            <strong>Data Set Diversity:</strong> A holistic model mandates diverse facial expressions under varied lighting conditions. Over-representing any particular lighting condition or expression would skew the model. Think of it as a facial symphony; too many repetitive notes, and the tune goes askew.
                        </li>
                        <li>
                            <strong>Image Quality:</strong> Blurry or poor-quality images are the bane of a good deepfake. Manual curation becomes essential, given the high standard required for each face. Even a slight increase in poor-quality images (above 2-3%) can notably degrade the final outcome.
                        </li>
                    </ul>
                ),
            },
            {
                header: 'Data Collection:',
                content: (
                    <span>
                        Direct videos of the subject ease the data collection process, presenting diverse angles and expressions. However, leveraging online images, like those used in the project, necessitates meticulous filtering and quality checks.
                        <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                            <li>
                                {' '}
                                <strong>Enhancing image quality:</strong> Techniques like General Adversarial Networks (GANs) can artificially enhance image quality. However, this introduces synthetic data, and moderation is key – keeping it below 10% of the total dataset is crucial.
                            </li>
                            <li>
                                <strong>Training:</strong> With a curated dataset in hand, the journey of 20-40 hours of model training begins. Periodic checks, every few ten-thousand iterations, ensure the model is on the right path. Training isn't a linear process but an art, with multiple subjective decisions determining the model's course.
                            </li>
                        </ul>
                    </span>
                ),
            },
            {
                header: 'Merging:',
                content: "Post-training, the act of merging ensues. This involves a frame-by-frame adjustment on the destination video, ensuring the transplanted face matches in terms of size, texture, and lighting. It's a meticulous process, but paradoxically, it's the shortest phase.",
            },
            {
                header: 'Insights & Takeaways:',
                content: <span>The longevity of the process is largely tethered to dataset creation. A high-quality dataset demands between 6,000 to 10,000 images. More extensive datasets translate to prolonged training but yield superior cloning fidelity. Certain videos, especially those with significant face obscurations like glasses or bangs, are non-viable for deepfaking. With no established online resources to guide the deepfaking journey, self-teaching was the only recourse. Over 500 hours of research, experimentation, and understanding distilled into one guiding principle: "Garbage in, garbage out." High fidelity in deepfakes is contingent on the quality of input data.</span>,
            },
            {
                header: 'Final Thoughts:',
                content: 'With no established online resources to guide the deepfaking journey, self-teaching was the only recourse. Over 500 hours of research, experimentation, and understanding distilled into one guiding principle: "Garbage in, garbage out." High fidelity in deepfakes is contingent on the quality of input data.',
            },
        ],
        images: [
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-DCE9D.jpg`, caption: 'An image that was manually cut out using XSeg, a tool used to specify what regions of an image are the face.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-BB6E4.jpg`, caption: 'An example of an image after running through the trained XSed model, with extracted landmarks. (nose, lips, eyebrows)' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-D63A9.jpg`, caption: 'The mask resulting from the XSeg model, showing the regions for training.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-7C2AF.jpg`, caption: 'A face that is fully prepared and ready for training.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-16A05.jpg`, caption: 'This is an example of a failed upscale using GAN. The images resulting from this method of upscaling can be nightmarish.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-448AC.jpg`, caption: 'A successful upscaling using GAN. As you can see when it works, it is excellent at enhancing human faces.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/unknown-4D6DB.jpg`, caption: 'An example of a good spread of faces. The axis show the orientation of the face. The lighter regions denote a higher density of faces in that oriention.' },
            { src: `${process.env.PUBLIC_URL}/images/deepfake/image-20BC2.jpg`, caption: 'The preview output while the trainer is running, learning the face shape and model details.' },
        ],
    };
    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <div>
                <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                    <div className="bg-dark text-white text-center py-5" style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', borderRadius: '30px', textWrap: 'balance', padding: '20px' }}>
                        <h1>{projectDetails.title}</h1>
                        {projectDetails.sections.map((section, index) => (
                            <div key={index}>
                                <h3>{section.header}</h3>
                                <p>{section.content}</p>
                            </div>
                        ))}
                        <h3>There is much to see, so the gallery section on this page is quite long. Enjoy!</h3>
                    </div>
                </Container>
            </div>
            {/* insert two videos here, side by side to compare the source to the final */}
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                {/* display a big (80%) image here */}
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/deepfake/source.mp4`} type="video/mp4" autoPlay muted />
                            <CardText className="text-center">The source video</CardText>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/deepfake/result.mp4`} type="video/mp4" autoPlay muted />
                            <CardText className="text-center">The final deepfake</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <CardImg top src={`${process.env.PUBLIC_URL}/images/deepfake/flowchart.png`} alt="Banner" style={{ cursor: 'pointer', borderRadius: '15px', objectFit: 'cover' }} />
                            <CardText className="text-center">The flowchart of the deepfaking process</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ borderRadius: '30px', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div >
    );
};

export default DeepFake;
