import React from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
const ProjectsDirectory = () => {
    // Example projects data
    const projects = [
        { title: 'Apex Tracking', img: '/images/thumbnails/Tracking_Thumbnail2.jpg', page: 'tracking' },
        { title: 'Lasers', img: '/images/thumbnails/Laser_Thumbnail.jpg', page: 'lasers' },
        { title: 'Deep faking', img: '/images/thumbnails/Deepfaking_Thumbnail.jpg', page: 'deepfake' },
        { title: 'Object Detection', img: '/images/thumbnails/ObjectDetection_Thumbnail.jpg', page: 'objectDetection' },
        { title: 'Rotation Advice', img: '/images/thumbnails/RotationAdvice_Thumbnail.jpg', page: 'rotationAdvice' },
        { title: 'Breaking Turtle Graphics', img: '/images/thumbnails/BreakingTurtleGraphics_Thumbnail.jpg', page: 'breakingTurtleGraphics' },
    ];

    const renderMaintext = () => {
        return (
            <div>
                <Container fluid className="text-white text-center py-5">
                    <div className="bg-dark text-white text-center py-5" style={{ borderRadius: '30px' }}>
                        <h1>Projects</h1>
                        <h5 style={{ ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' }, margin: 'auto', textWrap: 'balance' }}>Click on any of the images below to explore the project</h5>
                        <br />
                    </div>
                </Container>
            </div>
        );
    };

    return (
        <Container>
            {renderMaintext()}
            <Row>
                <Link to="/twitch" className="nav-link">
                    <Card style={{ cursor: 'pointer', borderRadius: '30px', maxWidth: '30%', margin: 'auto' }}>
                        <CardBody>
                            <CardTitle tag="h5" className="text-center">
                                <h2>Featured Project</h2>
                                <p>Twitch.To</p>
                                <CardImg top width="100%" src={process.env.PUBLIC_URL + '/images/thumbnails/TwitchTo_Thumbnail.svg'} alt="Twitch.To" style={{ borderRadius: '30px', maxWidth: '100%' }} />
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Link>
            </Row>
            <br />
            <Row>
                {projects.map((project, index) => (
                    <Col key={index} xs="12" md="4" lg="4" className="mb-4">
                        <Link to={`/${project.page}`} className="nav-link">
                            <Card style={{ cursor: 'pointer', borderRadius: '30px' }}>
                                <CardImg top width="100%" src={process.env.PUBLIC_URL + project.img} alt={project.title} style={{ borderRadius: '30px' }} />
                                <CardBody>
                                    <CardTitle tag="h5" className="text-center">
                                        {project.title}
                                    </CardTitle>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ProjectsDirectory;
