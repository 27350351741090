import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const TwitchTo = () => {
    const projectDetails = {
        title: 'Twitch.to: A free and fully featured AI image generation service.',
        sections: [
            {
                header: 'Introduction:',
                content: "Stable diffusion is a technology that uses AI to take prompts from users and convert them into images. I've been using stable diffusion for a couple of years now, and I think it's very cool, but there are drawbacks. The service is designed to be run locally, and if you want to go mobile, most platforms online want money. The ones that don't are often slow, heavily restricted, or have a low resolution. I wanted to make a service that was free, fast, and high quality. Twitch.to is the result of that desire.",
            },
            {
                header: 'Twitch.to, how it works:',
                content: (
                    <div>
                        In the backend, the AI model is run entirely on my own hardware, cutting out the need for a middleman and allowing for a much faster response time. The model used is a pretrained SDXL model, and it excels at generating high-resolution images, staying competent up to 2048x2048. The user inputs a prompt, and the model generates an image based on that prompt. The service is entirely free, and there are no limits on the number of images a user can generate, besides a credit system to prevent abuse. Each sampling step uses 1 credit, so at default each image takes 7 credits. Users start with 140 credits and are given a further 21 credits every day. Sampling step count is fully adjustable.
                        <br />
                        Unless they make an account, we do not store their images longer than 24 hours. If they choose to make an account, they can have a personal gallery of their images, and they can also delete their images at any time. No one can see the images generated other than the user that generated them. The system uses a reverse proxy to ensure security. Sadly, because of the sensitive details behind the authentication, the code cannot be open-sourced. This is because the reverse proxy provides protection against DDoS attacks, and keeps the server's IP hidden, mitigating the risk of p2p connections.
                        <br />
                        This system was built over the span of about 8 weeks worked on solely by myself through about 220 hours of work. The front end is built using nearly pure React, and the backend is built using Flask. The backend is where most of the magic really happens. The system is hosted on a computer running an i7 13700k, and a 4090 for GPU inferencing. The front and backend work in concert to provide a seamless experience for the user.
                        <br />
                        The website is also special for a couple of reasons beyond just being my favorite project:
                        <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                            <li>The website is made entirely in React, which allows us to have sleek performant features, as well as allow the use of React Responsive Grid Layout. The use of the responsive grid layout, and its role, is that it allows for a highly customizable and responsive layout that can be easily adjusted to fit any screen size. This is important because it allows for the website to be used on any device, PC or mobile.</li>
                            <li>Every single part of the website was custom built, I used a few existing libraries but 95% of everything is custom. This is important because it allows for a high degree of customization and control over the website, and it allows for the website to be easily updated and maintained.</li>
                            <li>Unlike every other service, we provide the ability to choose the pose of the generated subjects, and we allow for themes to be added to the generation.</li>
                            <li>Under the hood, themes are LoRAs or low order rank adapters. These matrices are applied over the layers of the model and bias it to generate images with certain themes. Sliders are provided to control the strength of the applied theme.</li>
                            <li>There is a filter mode that can be toggled by the user. It attempts to reduce the chance of an inappropriate image being generated. It does this by using a filter model to evaluate the prompt and determine if it is inappropriate. If it is, the prompt has its inappropriate parts trimmed. This is important because it allows for the website to be used by a wider audience, and it allows for the website to be used in a wider variety of settings.</li>
                        </ul>
                    </div>
                ),
            },
            {
                header: 'How an image is generated:',
                content: (
                    <div>
                        <ul style={{ textAlign: 'left', textWrap: 'balance' }}>
                            <li>First, the user selects themes and their prompt or pose. Certain things are also available for the user to enable or disable such as enhance and filter mode.</li>
                            <li>The user can adjust generation parameters and control many options within the generation.</li>
                            <li>Once the user is ready, they can click the generate button, and the model will start generating the image. The webpage then polls the server to determine its place in line and to get updates on the generation.</li>
                            <li>If they are currently the first in line, they will get a live preview of the generation, otherwise there is a loading bar.</li>
                            <li>The prompt goes through a variety of tuning steps and is then presented to the model.</li>
                            <li>The model then generates the image and sends it back to the user.</li>
                        </ul>
                    </div>
                ),
            },
            {
                header: 'Gallery',
                content: 'Example images generated by the model.',
            },
        ],
        images: [
            { src: `${process.env.PUBLIC_URL}/images/twitchto/example1.jpg`, caption: 'Example of a generated image.' },
            { src: `${process.env.PUBLIC_URL}/images/twitchto/example2.jpg`, caption: 'Another example of a generated image.' },
            { src: `${process.env.PUBLIC_URL}/images/twitchto/example3.jpg`, caption: 'Another example of a generated image.' },
            { src: `${process.env.PUBLIC_URL}/images/twitchto/example4.jpg`, caption: 'Another example of a generated image.' },
        ],
    };

    const [modal, setModal] = useState(false);
    const [modalImage, setModalImage] = useState({ src: '', caption: '' });

    const configureModal = (src, caption) => { setModalImage({ src, caption }); setModal(true); };

    const toggle = () => setModal(!modal);

    const renderModal = (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>{modalImage.caption}</ModalHeader>
            <ModalBody>
                <img src={modalImage.src} alt={modalImage.caption} style={{ width: '100%', height: 'auto' }} />
            </ModalBody>
        </Modal>
    );

    return (
        <div>
            {renderModal}
            <Container fluid className="text-center py-5" style={{ margin: 'auto' }}>
                <div className="bg-dark text-white text-center py-5" style={{ margin: 'auto', borderRadius: '30px', padding: '20px', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                    <h1>{projectDetails.title}</h1>
                    {projectDetails.sections.map((section, index) => (
                        <div key={index}>
                            <h3>{section.header}</h3>
                            <p>{section.content}</p>
                        </div>
                    ))}
                </div>
            </Container >
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <CardImg top src={`${process.env.PUBLIC_URL}/images/twitchto/diagram.png`} alt="Banner" style={{ cursor: 'pointer', borderRadius: '15px', objectFit: 'cover' }} onClick={() => configureModal(`${process.env.PUBLIC_URL}/images/twitchto/diagram.png`, 'A diagram of the frontend system.')} />
                            <CardText className="text-center">A diagram of the frontend system.</CardText>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    {projectDetails.images.map((image, index) => (
                        <Col key={index} xs="12" sm="6" md="4" lg="3">
                            <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                                <CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '30px', maxHeight: '300px', minHeight: '300px', objectFit: 'cover' }} onClick={() => configureModal(image.src, image.caption)} />
                                <CardText className="text-center">{image.caption}</CardText>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container fluid className="text-center py-5" style={{ margin: 'auto', ...window.innerWidth < 768 ? { maxWidth: '' } : { maxWidth: '80%' } }}>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px' }}>
                            <h3 style={{ margin: 'auto', textAlign: 'center', padding: '20px' }}>Go check it out! Guest mode allows for you to test out it's features without making an account!</h3>
                            <Button size="xxl" style={{ margin: 'auto', textAlign: 'center', padding: '20px' }} color="primary" href="https://twitch.to" target="_blank" rel="noreferrer">
                                Twitch.To
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default TwitchTo;
